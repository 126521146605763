import React, { useState, useEffect } from "react";

import moment from "moment";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import formatNumber from "format-number";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  ListSubheader,
  LinearProgress,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
} from "@material-ui/core";
import { Skeleton, Alert } from "@material-ui/lab";
import PreviousIcon from "@material-ui/icons/ArrowBack";
import NextIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { APIError, BaseAPI } from "../../data/BaseAPI";

import { useProfile } from "../../data/Profile";
import SortButton from "./components/SortButton";
import { WarrantyClaim, Customer, Profile, Dealer } from "../../types";
import localization from "../../utils/localizations";
import HinField from "../forms/fields/HinField";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  container: {
    paddingBottom: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  tableNav: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  statusPending: {
    backgroundColor: "#90CAF9",
  },
  statusApproved: {
    backgroundColor: "#A5D6A7",
  },
  statusWIP: {
    backgroundColor: "#FFCC80",
  },
  statusRTF: {
    backgroundColor: "#F48FB1",
  },
  statusDenied: {
    backgroundColor: "#EF5350",
  },
  statusReview: {
    backgroundColor: "#FFFFE8",
  },
  paginationControl: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  closeButton: {
    padding: 4,
  },
}));

interface FindClaimFormValues {
  serial_number: string;
  status: string;
  claim_number: string;
  reimb_acct: string;
  start_date: string;
  end_date: string;
  dealer: string | undefined;
  claims_url: string;
  search: string;
}

export default function WarrantyClaimCollection(props: {
  dealer?: Dealer;
  customer?: Customer;
  serialNumber?: string;
  className?: string;
  hideFilter?: boolean;
  showAll?: boolean;
}) {
  const classes = useStyles();

  const [filter, setFilter] = useState<FindClaimFormValues | undefined>(
    undefined,
  );
  const [showMarkAsPaidDialog, setShowMarkAsPaidDialog] = useState(false);
  const [allDealers, setAllDealers] = useState(
    ((window as any)._cached && (window as any)._cached["all_dealers"]
      ? (window as any)._cached["all_dealers"]
      : []) as Dealer[],
  );
  const [selectedDealer, setSelectedDealer] = useState(
    null as Dealer | null | undefined,
  );
  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [selectedWarrantyClaimIds, setSelectedWarrantyClaimIds] = useState(
    [] as string[],
  );
  const [warrantyClaims, setWarrantyClaims] = useState([] as WarrantyClaim[]);
  const [warrantyClaimsMeta, setWarrantyClaimsMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0,
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;
  const isAdmin = profile.role === "admin";

  const loadWarrantyClaims = async (
    page?: number,
    dealer?: Dealer | null | undefined,
    filter?: FindClaimFormValues,
  ) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = "warranty-claims/";
    if (profile.role === "admin") {
      url = filter?.claims_url ? filter.claims_url : "warranty-claims-pending/";
    }

    if (props.showAll) {
      url = "warranty-claims/";
    }

    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (filter?.dealer) kwargs.dealer = filter.dealer;
    if (dealer) kwargs.dealer = dealer.id;
    if (filter?.serial_number) {
      let serialNumber = filter.serial_number;
      if (serialNumber && serialNumber.startsWith("US")) {
        serialNumber = serialNumber.substring(2);
      }
      if (serialNumber) {
        kwargs.serial_number = serialNumber;
      }
    }
    if (filter?.status) kwargs.status = filter.status;
    if (filter?.claim_number) kwargs.claim_number = filter.claim_number;
    if (filter?.reimb_acct) kwargs.reimb_acct = filter.reimb_acct;
    if (filter?.start_date) kwargs.start_date = filter.start_date;
    if (filter?.end_date) kwargs.end_date = filter.end_date;
    if (filter?.search) kwargs.search = filter.search;
    if (props.customer) kwargs.customer = props.customer.id;
    if (props.serialNumber) kwargs.serial_number = props.serialNumber;
    if (sortKey) kwargs.ordering = sortKey;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && page > warrantyClaimsMeta.page) {
          const results = data.results as WarrantyClaim[];
          setWarrantyClaims(results);
        } else {
          setWarrantyClaims(data.results as WarrantyClaim[]);
        }

        setIsLoading(false);
        setWarrantyClaimsMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const downloadWarrantyClaims = async (
    page?: number,
    filterData?: FindClaimFormValues,
  ) => {
    if (isLoading) return;

    const api = new BaseAPI();

    let wUrl = "warranty-claims/";
    if (profile.role === "admin") {
      wUrl = filter?.claims_url
        ? filter.claims_url
        : "warranty-claims-pending/";
    }

    let url = `${api.baseUrl}warranty-claims-download/`;
    if (wUrl === "warranty-claims-pending/") {
      url = `${api.baseUrl}warranty-claims-pending-download/`;
    }

    if (props.showAll) {
      url = "warranty-claims/";
    }

    let kwargs: any = {};
    if (filterData?.dealer) kwargs.dealer = filterData.dealer;
    if (filterData?.serial_number) {
      let serialNumber = filterData.serial_number;
      if (serialNumber && serialNumber.startsWith("US")) {
        serialNumber = serialNumber.substring(2);
      }
      if (serialNumber) {
        kwargs.serial_number = serialNumber;
      }
    }
    if (filterData?.status) kwargs.status = filterData.status;
    if (filterData?.claim_number) kwargs.claim_number = filterData.claim_number;
    if (filterData?.reimb_acct) kwargs.reimb_acct = filterData.reimb_acct;
    if (filterData?.start_date) kwargs.start_date = filterData.start_date;
    if (filterData?.end_date) kwargs.end_date = filterData.end_date;
    if (filterData?.search) kwargs.search = filterData.search;

    kwargs.page_size = pageSize;
    if (page) kwargs.page = page;
    if (props.customer) kwargs.customer = props.customer.id;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    window.open(url, "_blank");
  };

  useEffect(() => {
    if (!profile.role) return;
    loadWarrantyClaims(undefined, selectedDealer, filter);
  }, [sortKey, profile.role, pageSize]);

  const loadAllDealers = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get("all-dealers/");
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached["all_dealers"])
          (window as any)._cached["all_dealers"] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (allDealers.length === 0) loadAllDealers();
  }, []);

  useEffect(() => {
    setSelectedDealer(props.dealer);
  }, [props.dealer]);

  const onSubmit = async (values: FindClaimFormValues) => {
    setIsLoading(true);
    setFilter(values);
    const api = new BaseAPI();
    await loadWarrantyClaims(undefined, selectedDealer, values);
    setIsLoading(false);
    setSelectedWarrantyClaimIds([]);
  };

  const onValidate = (values: FindClaimFormValues) => {
    const errors: any = {};

    return errors;
  };

  const isAllDisplayedClaimsSelected = (
    warrantyClaims: WarrantyClaim[],
    selectedWarrantyClaimIds: string[],
  ) => {
    let allSelected = warrantyClaims.length > 0;

    warrantyClaims.forEach((claim) => {
      if (!selectedWarrantyClaimIds.includes(claim.id)) allSelected = false;
    });
    return allSelected;
  };

  let paginations = Array.from(
    { length: warrantyClaimsMeta.num_pages },
    (_, i) => i + 1,
  );
  if (paginations.length > 10) {
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(
      paginations.length - 4,
      paginations.length - 1,
    );
    let middles: number[] = [];
    if (starts.length && ends.length && warrantyClaimsMeta.page > 1) {
      if (
        !starts.includes(warrantyClaimsMeta.page - 1) &&
        !ends.includes(warrantyClaimsMeta.page - 1)
      )
        middles.push(warrantyClaimsMeta.page - 1);
      if (
        !starts.includes(warrantyClaimsMeta.page) &&
        !ends.includes(warrantyClaimsMeta.page)
      )
        middles.push(warrantyClaimsMeta.page);
      if (
        !starts.includes(warrantyClaimsMeta.page + 1) &&
        !ends.includes(warrantyClaimsMeta.page + 1)
      )
        middles.push(warrantyClaimsMeta.page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }
  return (
    <div className={classes.root}>
      {!props.hideFilter && (
        <Paper style={{ paddingTop: 12 }}>
          <Form
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={{
              serial_number: "",
              status: "",
              claim_number: "",
              reimb_acct: "",
              start_date: "",
              end_date: "",
              dealer: props.dealer ? props.dealer.id : undefined,
              claims_url: "warranty-claims-pending/",
              search: "",
            }}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              errors,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classes.root}
                style={{ margin: 12 }}
              >
                <Grid container>
                  <Grid container spacing={3}>
                    <Field name="search">
                      {(props) => (
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <TextField
                              id={props.input.name}
                              label={"Search"}
                              placeholder="Enter keywords to search..."
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid container spacing={3}>
                    <Field name="dealer">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <InputLabel>Dealer</InputLabel>
                            <Select
                              id={props.input.name}
                              label={"Dealer"}
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={(event) => {
                                props.input.onChange(event);
                                if (!event.target.value) {
                                  setSelectedDealer(undefined);
                                  return;
                                }

                                allDealers.filter((dealer) => {
                                  if (dealer.id == event.target.value) {
                                    setSelectedDealer(dealer);
                                  }
                                });
                              }}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                            >
                              <ListSubheader>Active Dealers</ListSubheader>
                              <MenuItem value="">&mdash;</MenuItem>
                              {allDealers
                                .filter((dealer) => dealer.active)
                                .map((dealer, i) => (
                                  <MenuItem
                                    key={`dealer-menu-item-${i}`}
                                    value={dealer.id}
                                  >
                                    {dealer.customer_name}{" "}
                                    {dealer.customer_number
                                      ? `(${dealer.customer_number.padStart(5, "0")})`
                                      : ""}
                                  </MenuItem>
                                ))}
                              <ListSubheader>Inactive Dealers</ListSubheader>
                              {allDealers
                                .filter((dealer) => !dealer.active)
                                .map((dealer, i) => (
                                  <MenuItem
                                    key={`dealer-menu-item-inactive-${i}`}
                                    value={dealer.id}
                                  >
                                    {dealer.customer_name}{" "}
                                    {dealer.customer_number
                                      ? `(${dealer.customer_number.padStart(5, "0")})`
                                      : ""}
                                  </MenuItem>
                                ))}
                            </Select>
                            {(props.meta.error || props.meta.submitError) &&
                              props.meta.touched && (
                                <Typography style={{ color: "red" }}>
                                  {props.meta.error || props.meta.submitError}
                                </Typography>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                    </Field>

                    <Field name="status">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <InputLabel>Status</InputLabel>
                            <Select
                              id={props.input.name}
                              label={"Status"}
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={(event) => {
                                props.input.onChange(event);
                                if (
                                  ["", "wip", "pending", "rtf"].indexOf(
                                    event.target.value as string,
                                  ) === -1
                                ) {
                                  form.change("claims_url", "warranty-claims/");
                                }
                              }}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="">-</MenuItem>
                              <MenuItem value="wip">Work in Progress</MenuItem>
                              <MenuItem value="pending">Pending</MenuItem>
                              <MenuItem value="approved">Approved</MenuItem>
                              <MenuItem value="paid">Paid</MenuItem>
                              <MenuItem value="denied">Denied</MenuItem>
                              <MenuItem value="canceled">Canceled</MenuItem>
                              <MenuItem value="converted">Converted</MenuItem>
                              <MenuItem value="rtf">RTF</MenuItem>
                              <MenuItem value="closed">Closed</MenuItem>
                            </Select>
                            {(props.meta.error || props.meta.submitError) &&
                              props.meta.touched && (
                                <Typography style={{ color: "red" }}>
                                  {props.meta.error || props.meta.submitError}
                                </Typography>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                    </Field>

                    <Field name="start_date">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <TextField
                              id={props.input.name}
                              label={"Start Date"}
                              variant="outlined"
                              type="date"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>

                    <Field name="end_date">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <TextField
                              id={props.input.name}
                              label={"End Date"}
                              variant="outlined"
                              type="date"
                              placeholder=""
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>
                  </Grid>

                  <Grid container spacing={3}>
                    <Field name="serial_number">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <HinField
                              id={props.input.name}
                              label={"Serial Number / HIN"}
                              placeholder="LYGAB123E456"
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>

                    <Field name="claim_number">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <TextField
                              id={props.input.name}
                              label={"Claim Number"}
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>

                    <Field name="reimb_acct">
                      {(props) => (
                        <Grid item xs={3}>
                          <FormControl
                            fullWidth
                            className={classes.fieldContainer}
                            error={
                              props.meta.error && props.meta.touched
                                ? !!props.meta.error
                                : undefined
                            }
                          >
                            <TextField
                              id={props.input.name}
                              label={"Check Number"}
                              variant="outlined"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? true
                                  : false
                              }
                              helperText={
                                (props.meta.error || props.meta.submitError) &&
                                props.meta.touched
                                  ? props.meta.error || props.meta.submitError
                                  : undefined
                              }
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Field>
                    <Grid item xs={3}>
                      {profile?.role === "admin" && (
                        <div style={{ marginTop: -16 }}>
                          <Field name="claims_url">
                            {(props) => (
                              <FormControl
                                component="fieldset"
                                error={
                                  props.meta.error && props.meta.touched
                                    ? !!props.meta.error
                                    : undefined
                                }
                              >
                                <RadioGroup
                                  id={props.input.name}
                                  aria-label="Listing"
                                  name={props.input.name}
                                  value={props.input.value}
                                  onChange={(event) => {
                                    props.input.onChange(event);
                                  }}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="warranty-claims-pending/"
                                    control={<Radio />}
                                    label="Active Claims"
                                  />
                                  <FormControlLabel
                                    value="warranty-claims/"
                                    control={<Radio />}
                                    label="All Claims"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      )}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="small"
                          disabled={isLoading}
                        >
                          Find
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<CloudDownloadIcon />}
                          disabled={isLoading}
                          size="small"
                          style={{ marginLeft: 12 }}
                          onClick={() => {
                            console.log("download", values);
                            downloadWarrantyClaims(
                              warrantyClaimsMeta.page,
                              values,
                            );
                          }}
                        >
                          Download
                        </Button>
                        <Button
                          variant="contained"
                          disabled={isLoading}
                          size="small"
                          style={{ marginLeft: 12 }}
                          onClick={() => {
                            setSelectedDealer(undefined);
                            setFilter(undefined);
                            let newValues = Object.assign({}, values, {
                              dealer: undefined,
                              serial_number: "",
                              status: "",
                              claim_number: "",
                              reimb_acct: "",
                              start_date: "",
                              end_date: "",
                              search: "",
                              claim_url: values.claims_url,
                            });
                            loadWarrantyClaims(undefined, undefined, newValues);
                            form.change("dealer", newValues.dealer);
                            form.change(
                              "serial_number",
                              newValues.serial_number,
                            );
                            form.change("status", newValues.status);
                            form.change("claim_number", newValues.claim_number);
                            form.change("reimb_acct", newValues.reimb_acct);
                            form.change("start_date", newValues.start_date);
                            form.change("end_date", newValues.end_date);
                            form.change("search", newValues.search);
                            form.change("claims_url", newValues.claims_url);
                          }}
                        >
                          Clear
                        </Button>
                        {isLoading && (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  {!pristine && Object.keys(errors as any).length > 0 && (
                    <Grid item xs={12}>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Alert severity="error">
                          Please make sure all required fields are correctly
                          filled!
                        </Alert>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          />
        </Paper>
      )}
      <Collapse in={selectedWarrantyClaimIds.length > 0}>
        <Paper style={{ marginBottom: 10, whiteSpace: "nowrap" }}>
          <Toolbar>
            <Typography
              color="inherit"
              variant="subtitle1"
              component="div"
              style={{ flex: "1 1 100%" }}
            >
              {selectedWarrantyClaimIds.length} selected
              <Button
                color="primary"
                size="small"
                onClick={() => setSelectedWarrantyClaimIds([])}
                style={{ marginLeft: 12 }}
              >
                Clear
              </Button>
            </Typography>
            {isAdmin && (
              <Button
                color="primary"
                type="submit"
                disabled={isLoading}
                onClick={() => setShowMarkAsPaidDialog(true)}
                style={{ width: 328 }}
              >
                Mark Selected Claims as Paid
              </Button>
            )}
          </Toolbar>
        </Paper>
      </Collapse>
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          <TableHead>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={10} style={{ padding: 0 }}>
                  <LinearProgress
                    id="warranty-list-loading"
                    style={{ width: "100%" }}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                {isAdmin && (
                  <Checkbox
                    indeterminate={
                      selectedWarrantyClaimIds.length > 0 &&
                      !isAllDisplayedClaimsSelected(
                        warrantyClaims,
                        selectedWarrantyClaimIds,
                      )
                    }
                    checked={isAllDisplayedClaimsSelected(
                      warrantyClaims,
                      selectedWarrantyClaimIds,
                    )}
                    onChange={(event, checked) => {
                      if (checked) {
                        const newSelectedWarrantyClaimIds = [
                          ...selectedWarrantyClaimIds,
                        ];
                        warrantyClaims.forEach((claim) => {
                          if (!newSelectedWarrantyClaimIds.includes(claim.id)) {
                            newSelectedWarrantyClaimIds.push(claim.id);
                          }
                        });
                        setSelectedWarrantyClaimIds(
                          newSelectedWarrantyClaimIds,
                        );
                      } else {
                        const allClaimIds = warrantyClaims.map(
                          (claim) => claim.id,
                        );
                        const newSelectedWarrantyClaimIds =
                          selectedWarrantyClaimIds.filter(
                            (claimId) => !allClaimIds.includes(claimId),
                          );
                        setSelectedWarrantyClaimIds(
                          newSelectedWarrantyClaimIds,
                        );
                      }
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <SortButton
                  sortKey="claim_number"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "left" }}
                >
                  Claim Number
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="dealer__customer_name"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Dealer
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="owner_contact"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Owner
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="date_submitted"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Date Submitted
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="serial_number"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Serial Number
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="dealer__email"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Dealer Email
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="owner_email"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Customer Email
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="reimb_acct"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Check #
                </SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey="status"
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{ textAlign: "right" }}
                >
                  Status
                </SortButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warrantyClaims.map((claim, i) => {
              let colorClass = "";
              switch (claim.status) {
                case "pending":
                  colorClass = classes.statusPending;
                  break;
                case "wip":
                  colorClass = classes.statusWIP;
                  break;
                case "approved":
                  colorClass = classes.statusApproved;
                  break;
                case "denied":
                  colorClass = classes.statusDenied;
                  break;
                case "rtf":
                  colorClass = classes.statusRTF;
                  break;
                case "review":
                  colorClass = classes.statusReview;
                  break;
              }
              return (
                <TableRow
                  key={`warranty-claim-item-${claim.id}`}
                  className={`${colorClass}`}
                >
                  <TableCell>
                    {isAdmin && (
                      <Checkbox
                        checked={selectedWarrantyClaimIds.includes(claim.id)}
                        onChange={(event, checked) => {
                          if (checked) {
                            const newSelectedWarrantyClaimIds = [
                              ...selectedWarrantyClaimIds,
                            ];
                            newSelectedWarrantyClaimIds.push(claim.id);
                            setSelectedWarrantyClaimIds(
                              newSelectedWarrantyClaimIds,
                            );
                          } else {
                            setSelectedWarrantyClaimIds(
                              selectedWarrantyClaimIds.filter(
                                (value) => value !== claim.id,
                              ),
                            );
                          }
                        }}
                      />
                    )}
                    {!isAdmin && (
                      <span style={{ color: "#888" }}>
                        {1 +
                          i +
                          (warrantyClaimsMeta.page - 1) *
                            warrantyClaimsMeta.page_size}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.claim_number}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.dealer?.customer_name} (
                      {claim.dealer?.customer_number.padStart(5, "0")})
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.owner_contact}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.date_submitted
                        ? moment(claim.date_submitted).format("MMMM Do YYYY")
                        : ""}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.serial_number}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.dealer_email}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.owner_email}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.reimb_acct}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}warranty/claims/${claim.id}/`}
                      size="medium"
                      style={{ textTransform: "none", padding: 0 }}
                    >
                      {claim.status_text}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {warrantyClaims.length === 0 && (
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={9}>
                  {isLoading ? "Loading..." : ""}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                {isLoading && <CircularProgress size={20} />}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={8}
                count={warrantyClaimsMeta.count}
                rowsPerPage={warrantyClaimsMeta.page_size}
                page={warrantyClaimsMeta.page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={(event: any, newPage: number) => {
                  if (!isLoading)
                    loadWarrantyClaims(newPage + 1, selectedDealer, filter);
                }}
                onChangeRowsPerPage={(event: any) => {
                  if (!isLoading) setPageSize(parseInt(event.target.value, 10));
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <MarkAsPaidDialog
        open={!!showMarkAsPaidDialog}
        selectedWarrantyClaimIds={selectedWarrantyClaimIds}
        onClose={() => setShowMarkAsPaidDialog(false)}
        onCompleted={() => {
          loadWarrantyClaims(1, selectedDealer, filter);
          setShowMarkAsPaidDialog(false);
          setSelectedWarrantyClaimIds([]);
        }}
      />
    </div>
  );
}

function TablePaginationActions(props: {
  count: number;
  onChangePage?: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage: number;
}) {
  const classes = useStyles();
  const { count, page, rowsPerPage } = props;

  const onChangePage = (event: any, newPage: number) => {
    if (props.onChangePage) props.onChangePage(event, newPage);
  };

  let numPages = Math.ceil(props.count / props.rowsPerPage);
  let paginations = Array.from({ length: numPages }, (_, i) => i + 1);
  if (paginations.length > 10) {
    let page = props.page + 1;
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 3, paginations.length);
    let middles: number[] = [];
    if (starts.length && ends.length && page > 1) {
      if (!starts.includes(page - 1) && !ends.includes(page - 1))
        middles.push(page - 1);
      if (!starts.includes(page) && !ends.includes(page)) middles.push(page);
      if (
        page + 1 < numPages &&
        !starts.includes(page + 1) &&
        !ends.includes(page + 1)
      )
        middles.push(page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }

  return (
    <div className={classes.paginationControl}>
      <IconButton
        onClick={(event) => {
          onChangePage(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, page - 1);
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {paginations.map((i) => (
        <Button
          key={`pagination-button-${i}`}
          disabled={i - 1 === props.page}
          onClick={() => {
            onChangePage(undefined, i - 1);
          }}
        >
          {i}
        </Button>
      ))}
      <IconButton
        onClick={(event) => {
          onChangePage(event, page + 1);
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

interface MarkPaidFormValues {
  reimb_acct: string;
  date_of_check: string;
  paid_date: string;
}

function MarkAsPaidDialog(props: {
  open: boolean;
  selectedWarrantyClaimIds: string[];
  onClose: () => void;
  onCompleted: () => void;
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const onSubmit = async (values: MarkPaidFormValues) => {
    setIsLoading(true);
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post(
        {
          reimb_acct: values.reimb_acct,
          date_of_check: values.date_of_check,
          paid_date: values.paid_date,
          claims: props.selectedWarrantyClaimIds,
        },
        "warranty-claims/bulk_mark_as_paid/",
      );
      if (result.success) {
        props.onCompleted();
      } else {
        setIsLoading(false);
        return result;
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsLoading(false);
        return (e as APIError).errorData;
      }
    }
    setIsLoading(false);
  };

  const onValidate = (values: MarkPaidFormValues) => {
    const errors: any = {};

    return errors;
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth
      onClose={() => props.onClose()}
    >
      <DialogTitle>Mark Selected Claims as Paid</DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        initialValues={{
          reimb_acct: "",
          date_of_check: undefined,
          paid_date: undefined,
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            style={{ margin: 12 }}
          >
            <DialogContent>
              <Field name="reimb_acct">
                {(props) => (
                  <FormControl
                    fullWidth
                    className={classes.fieldContainer}
                    error={
                      props.meta.error && props.meta.touched
                        ? !!props.meta.error
                        : undefined
                    }
                  >
                    <TextField
                      id={props.input.name}
                      label={"Check #"}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? true
                          : false
                      }
                      helperText={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? props.meta.error || props.meta.submitError
                          : undefined
                      }
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="date_of_check">
                {(props) => (
                  <FormControl
                    fullWidth
                    className={classes.fieldContainer}
                    error={
                      props.meta.error && props.meta.touched
                        ? !!props.meta.error
                        : undefined
                    }
                  >
                    <TextField
                      id={props.input.name}
                      label={"Date of Check"}
                      variant="outlined"
                      type="date"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? true
                          : false
                      }
                      helperText={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? props.meta.error || props.meta.submitError
                          : undefined
                      }
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="paid_date">
                {(props) => (
                  <FormControl
                    fullWidth
                    className={classes.fieldContainer}
                    error={
                      props.meta.error && props.meta.touched
                        ? !!props.meta.error
                        : undefined
                    }
                  >
                    <TextField
                      id={props.input.name}
                      label={"Paid Date"}
                      variant="outlined"
                      type="date"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? true
                          : false
                      }
                      helperText={
                        (props.meta.error || props.meta.submitError) &&
                        props.meta.touched
                          ? props.meta.error || props.meta.submitError
                          : undefined
                      }
                    />
                  </FormControl>
                )}
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Processing" : "Proceed"}
              </Button>
              <Button onClick={props.onClose} disabled={isLoading}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      />

      <Snackbar
        key={"mark-as-paid-error-snackbar"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackBar(false)}
        onExited={() => setShowErrorSnackBar(false)}
        message="Error saving your data. Please try again. Contact us if the issue persist."
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setShowErrorSnackBar(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </Dialog>
  );
}
