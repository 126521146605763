import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  CircularProgress,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Slide,
  Menu,
  MenuItem,
  FormControl,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Skeleton } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";

import localization from "../../utils/localizations";
import { Header } from "../../views/panels/Header";

import { SessionContext } from "../../data/Session";

import { BaseAPI } from "../../data/BaseAPI";

import { useProfile } from "../../data/Profile";

import { Order, User, DealerPermission } from "../../types";

import OrderList from "../../views/collections/OrderList";
import DateTimeView from "../../views/singles/DateTimeView";
import OrderDetail from "../../views/singles/OrderDetail";
import OrderCollection from "../../views/collections/OrderCollection";
import { downloadFile } from "../../utils/downloadFile";
import { levenshteinDistance } from "../../utils/formUtils";
import moment, { fn } from "moment";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: "relative",
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10,
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },

  uploadContainer: {
    padding: 10,
    marginBottom: 20,
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    marginBottom: 10,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignRight: {
    textAlign: "right",
  },
  marginBottom: {
    marginBottom: 16,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  formButtons: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
  },
  fieldContainer: {
    marginBottom: 20,
  },
  link: {
    color: "#115293",
  },
  table: {},
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoadingView() {
  return (
    <div style={{ position: "relative", padding: 10 }}>
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
    </div>
  );
}

export default function FBInvoiceToNPCSVPage() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();
  const [navigateBack, setNavigateBack] = useState(false);

  const [showEditOrderForm, setShowEditOrderForm] = useState(false);
  const [editOrder, setEditOrder] = useState<Order | null>(null);
  const [editOrderCounter, setEditOrderCounter] = useState(0);

  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] =
    useState<HTMLElement | null>(null);

  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);
  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessBoatOrderingPortal = (
    permissions?: DealerPermission[],
    role?: string,
  ) => {
    let hasPermission = false;
    if (role === "admin") hasPermission = true;
    if (permissions) {
      permissions.forEach((permission) => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  };

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (
      <>
        <Drawer anchor="bottom" open={open} onClose={goBack}>
          <Typography variant="h2" style={{ marginLeft: 14, marginTop: 14 }}>
            Loading...
          </Typography>
          <div style={{ height: 60 }}></div>
        </Drawer>
        {navigateBack && <Redirect to={backUrl} />}
      </>
    );
  }

  return (
    <SessionContext.Consumer>
      {({ session }) => (
        <>
          <Header
            session={session}
            title={`Tools`}
            breadcrumbs={[
              {
                title: "Orders",
                link: `${appConfig.homepage}orders/`,
              },
              {
                title: "Transform FishBowl Invoice CSV",
                link: `${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`,
              },
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={RouterLink}
                  to={`${appConfig.homepage}order-form/`}
                >
                  New Order
                </Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/`}
                  style={{ marginLeft: 12 }}
                >
                  Orders
                </Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/quotes/`}
                  style={{ marginLeft: 12 }}
                >
                  Quotes
                </Button>
                <Button
                  variant="contained"
                  aria-controls="order-tools-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    setToolsMenuAnchorEl(e.currentTarget);
                  }}
                  endIcon={<ExpandMoreIcon />}
                  style={{ float: "right" }}
                >
                  Tools
                </Button>
                <Menu
                  id="order-tools-menu"
                  anchorEl={toolsMenuAnchorEl}
                  keepMounted
                  open={Boolean(toolsMenuAnchorEl)}
                  onClose={() => setToolsMenuAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setToolsMenuAnchorEl(null);
                    }}
                    component={RouterLink}
                    to={`${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`}
                  >
                    Transform FishBowl Invoice into NorthPoint CSV
                  </MenuItem>
                </Menu>
              </div>
            </Paper>

            <Paper style={{ padding: 8 }}>
              <InvoiceTransformerView />
            </Paper>
          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}

function InvoiceTransformerView() {
  const classes = useStyles();
  const [showCSVSelector, setShowCSVSelector] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<any | null>();
  const [csvFields, setCSVFields] = useState<string[]>([]);
  const [csvData, setCSVData] = useState<any | null>(null);
  const [enginesData, setEnginesData] = useState<any[]>([]);
  const [csvDealerName, setCSVDealerName] = useState("");
  const [crossReferenceList, setCrossReferenceList] = useState<string[][]>([]);
  const [showCrossReferenceTable, setShowCrossReferenceTable] = useState(false);
  const [matchedDealerName, setMatchedDealerName] = useState("");

  const uploadCSVFile = async (file: any) => {
    let success = false;
    setIsLoading(true);
    setErrorMessage("");
    let formData = new FormData();
    formData.append("csv_file", file);

    const api = new BaseAPI();
    api.path = "tools/";
    try {
      const [result, response, error] = await api.post(
        formData,
        "transform-fb-invoice-to-northpoint-csv/",
      );
      console.log(result);
      if (result && result.success) {
        if (result.dealer_name && result.cross_reference_list) {
          let score = 1;
          let plantId = "";
          let slocId = "";
          let dealerNumber = "";
          let matchedDealerName = "";
          let csvDealerName = result.dealer_name.trim().toLowerCase();

          (result.cross_reference_list as string[][])
            .filter((row, i) => i > 0)
            .forEach((row) => {
              let dealerName = row[8].trim();

              if (!dealerName) return;
              if (dealerName.toLowerCase() === csvDealerName) {
                score = 0;
                dealerNumber = row[7];
                slocId = row[3];
                plantId = row[5];
                matchedDealerName = dealerName;
                return;
              }
              let distance = levenshteinDistance(
                dealerName.toLowerCase(),
                csvDealerName,
              );
              let currentScore = distance / dealerName.length;
              if (currentScore < score) {
                dealerNumber = row[7];
                slocId = row[3];
                plantId = row[5];
                matchedDealerName = dealerName;
                score = currentScore;
                console.log(currentScore, dealerName);
              }
            });

          console.log(score, matchedDealerName);
          result.data["SLOC ID"] = slocId;
          result.data["Plant ID"] = plantId;
          result.data["Dealer Number"] = dealerNumber;
          setMatchedDealerName(matchedDealerName);
        }
        setCSVData(result.data);
        setEnginesData(result.engines);
        setCSVFields(result.fields);
        setCrossReferenceList(result.cross_reference_list);
        setCSVDealerName(result.dealer_name);
        setShowCSVSelector(false);
        setSelectedFile(null);
      }
      if (result && !result.success && result.message) {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(`${error}`);
    }
    setIsLoading(false);
  };

  let requiredFields: any = {
    "SLOC ID": true,
    "Plant ID": true,
    "Dealer Number": true,
    "Invoice Number": true,
    "Invoice Date": true,
    "Model Number": true,
    "Model Year": true,
    "Serial/VIN": true,
    "Product Class": false,
    "Terms Code": true,
    Quantity: true,
    "Item Amount": true,
    "Ship Date": false,
    "Receipt Date": false,
    "Approval Number": true,
    "PO Number": false,
    "Sales Order": false,
    "Item Description": false,
    Color: false,
    "Case Number": false,
    "Chassis Number": false,
    Manufacturer: false,
  };

  const validateCSVData = () => {
    let errors = csvFields
      .map((field) => {
        let value = csvData[field];
        if (requiredFields[field]) {
          if (!value) return field;
        }
        return "";
      })
      .filter((value) => !!value);

    return errors;
  };

  const generateNorthPointCSV = async () => {
    let success = false;
    setIsLoading(true);
    setErrorMessage("");

    let csvRows = [csvData];
    enginesData.forEach((engine) => {
      csvRows.push({
        ...csvData,
        "Serial/VIN": engine["Serial/VIN"],
        "Item Description": engine["Item Description"],
        "Model Number": engine["Model Number"],
        "Item Amount": engine["Item Amount"],
      });
    });

    const api = new BaseAPI();
    api.path = "tools/";
    try {
      const [result, response, error] = await api.post(
        { data_lines: csvRows },
        "generate-northpoint-csv/",
      );
      console.log(result);
      if (result && result.success) {
        let datestring = moment().format("YYYYMMDDhhmmss");
        downloadFile(result.csv, `northpoint-invoice-${datestring}.csv`);
      }
      if (result && !result.success && result.message) {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(`${error}`);
    }
    setIsLoading(false);
  };

  if (showCSVSelector) {
    return (
      <div>
        <h3 className={classes.title}>Select a FishBowl Invoice CSV File</h3>
        <Dropzone
          accept=".csv"
          onDrop={(acceptedFiles: any[]) => {
            if (acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              setSelectedFile(file);
              setErrorMessage("");
              uploadCSVFile(file);
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className={classes.uploadContainer}>
              <div className={classes.dropzone} {...getRootProps()}>
                <input {...getInputProps()} />
                <CloudUploadIcon fontSize="large" />
                <Typography className={classes.textAlignCenter}>
                  Choose a file or drag here
                </Typography>
              </div>
              <div>
                {selectedFile && selectedFile.name && (
                  <div>
                    <Chip
                      label={selectedFile.name ? selectedFile.name : ""}
                      className={classes.marginBottom}
                      onDelete={() => {
                        setSelectedFile(null);
                      }}
                    />
                  </div>
                )}
              </div>
              {!!errorMessage && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                  <Alert severity="error">{errorMessage}</Alert>
                </div>
              )}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading}
                  endIcon={<ChevronRightIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedFile) {
                      uploadCSVFile(selectedFile);
                    } else {
                      setErrorMessage("Please select a csv file");
                    }
                  }}
                >
                  {isLoading ? "Loading..." : "Proceed"}
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }

  const renderField = (
    fieldName: string,
    fieldValueSource: any,
    onChanged: (field: string, value: any) => void,
  ) => {
    let value = fieldValueSource[fieldName];
    let fieldType = "text";
    let maxInputLength = 60;
    let requiredField = false;
    let helperText: any = undefined;
    let inputProps: any = {};
    let idFields = ["SLOC ID", "Plant ID", "Dealer Number"];
    let maxInputLengths: any = {
      "SLOC ID": 9,
      "Plant ID": 9,
      "Dealer Number": 128,
      "Invoice Number": 22,
      "Invoice Date": 10,
      "Model Number": 12,
      "Model Year": 4,
      "Serial/VIN": 17,
      "Product Class": 20,
      "Terms Code": 20,
      Quantity: 3,
      "Item Amount": 15,
      "Ship Date": 10,
      "Receipt Date": 10,
      "Approval Number": 10,
      "PO Number": 17,
      "Sales Order": 17,
      "Item Description": 60,
      Color: 28,
      "Case Number": 24,
      "Chassis Number": 20,
      Manufacturer: 36,
    };
    let fieldTypes: any = {
      "SLOC ID": "number",
      "Plant ID": "number",
      "Dealer Number": "text",
      "Invoice Number": "text",
      "Invoice Date": "date",
      "Model Number": "text",
      "Model Year": "number",
      "Serial/VIN": "text",
      "Product Class": "text",
      "Terms Code": "select",
      Quantity: "number",
      "Item Amount": "number",
      "Ship Date": "date",
      "Receipt Date": "date",
      "Approval Number": "text",
      "PO Number": "text",
      "Sales Order": "text",
      "Item Description": "text",
      Color: "text",
      "Case Number": "text",
      "Chassis Number": "text",
      Manufacturer: "text",
    };

    let fieldDescriptions: any = {
      "SLOC ID":
        "The Wholesale SLOC ID that will be set as the Purchased From supplier.",
      "Plant ID":
        "The Wholesale Plant ID that will be set as the Purchased From plant.",
      "Dealer Number":
        "The supplier’s dealer number cross-referenced to the Wholesale Dealer Account.",
      "Invoice Number": "The supplier’s invoice number",
      "Invoice Date": "The supplier’s invoice date",
      "Model Number": "Model number of the item.",
      "Model Year": "Model year of the item",
      "Serial/VIN": "Serial number or VIN of the item",
      "Product Class":
        "Product class code that will be cross-referenced to the Wholesale product class.",
      "Terms Code":
        "Terms code that will be cross-referenced to the finance plan",
      Quantity: "Must be equal to or greater than 1",
      "Item Amount":
        "Financed amount of the item.  This amount must be positive and contain positions for the cents in the item amount. The field must include the decimal. $1,000.00 must appear as 1000.00",
      "Ship Date": "Date the inventory is shipped",
      "Receipt Date": "Date the inventory is received",
      "Approval Number": "Approval number associated with the invoice",
      "PO Number": "PO Number associated with the invoice",
      "Sales Order": "Sales Order associated with the invoice",
      "Item Description": "Description of the item",
      Color: "Color of the item",
      "Case Number": "Case number of the item",
      "Chassis Number": "Chassis number of the item",
      Manufacturer: "Name of the manufacturer of the item",
    };

    if (fieldTypes[fieldName]) {
      fieldType = fieldTypes[fieldName];
    }
    if (fieldTypes[fieldName] && fieldTypes[fieldName] === "date") {
      value = moment(fieldValueSource[fieldName]).format("YYYY-MM-DD");
    }
    if (maxInputLengths[fieldName]) {
      maxInputLength = maxInputLengths[fieldName];
    }
    if (requiredFields[fieldName]) {
      requiredField = true;
    }

    inputProps["maxlength"] = maxInputLength;
    if (fieldDescriptions[fieldName]) {
      helperText = (
        <div>
          <div>{fieldDescriptions[fieldName]}</div>
        </div>
      );
    }
    if (idFields.includes(fieldName)) {
      helperText = (
        <div>
          {fieldDescriptions[fieldName]}
          {!!matchedDealerName && (
            <div>
              {fieldName} is guessed from the dealer name included in the
              invoice ("<span style={{ color: "red" }}>{csvDealerName}</span>")
              which is similar with "
              <span style={{ color: "green" }}>{matchedDealerName}</span>"
              dealer found in the dealer cross reference list.
            </div>
          )}
          <div>
            <a
              className={classes.link}
              href="javascript:void(0)"
              onClick={() => setShowCrossReferenceTable(true)}
            >
              Select a dealer manually
            </a>
          </div>
        </div>
      );
    }
    if (fieldName === "Model Number") {
      helperText = (
        <div>
          <div>
            {fieldDescriptions[fieldName]} {fieldName} is guessed from the boat
            model included in the invoice. If it's incorrect, please update them
            as necessary. Maximum length is 12 characters.
          </div>
        </div>
      );
    }
    if (fieldName === "Terms Code") {
      let termCodes = ["CASH", "NP FF 3M", "NP FF 6M", "NP FF 9M", "NP FF 12M"];

      if (value && !termCodes.includes(value)) {
        termCodes.push(value);
      }

      return (
        <FormControl variant="outlined">
          <InputLabel id="term-code-label">Term Code</InputLabel>
          <Select
            labelId="term-code-label"
            id="term-code"
            label="Term Code"
            value={value}
            required={requiredField}
            onChange={(e) => {
              let newValue = e.target.value;
              if (newValue && (newValue as string).substring)
                newValue = (newValue as string).substring(0, maxInputLength);
              onChanged(fieldName, newValue);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {termCodes.map((opt, i) => (
              <MenuItem value={opt} key={`term-code-item-${i}`}>
                {opt}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
    }
    return (
      <TextField
        id={fieldName.replaceAll(" ", "").replaceAll("/", "-")}
        label={fieldName}
        variant="outlined"
        name={fieldName}
        value={value}
        type={fieldType}
        inputProps={inputProps}
        helperText={helperText}
        required={requiredField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          let newValue = e.target.value;
          if (newValue.substring)
            newValue = newValue.substring(0, maxInputLength);
          if (e.target.value && fieldType === "date") {
            newValue = moment(e.target.value).format("MM/DD/YYYY");
          }
          onChanged(fieldName, newValue);
        }}
      />
    );
  };

  return (
    <div style={{ padding: 16 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!isLoading) {
            let errorFields = validateCSVData();
            if (errorFields.length > 0) {
              setErrorMessage(
                `Please make sure the following fields are correctly filled: ${errorFields.join(", ")}`,
              );
            } else {
              generateNorthPointCSV();
            }
          }
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {csvFields.map((fieldName, i) => (
              <FormControl
                key={`field-${i}`}
                fullWidth
                className={classes.fieldContainer}
              >
                {renderField(fieldName, csvData, (fieldName, newValue) => {
                  let newCSVData = Object.assign({}, csvData);
                  newCSVData[fieldName] = newValue;
                  setCSVData(newCSVData);
                })}
              </FormControl>
            ))}
          </Grid>
        </Grid>

        {enginesData.map((engine, i) => (
          <Grid container>
            <Grid item xs={12}>
              <hr />
              <Typography
                variant="h6"
                component="h6"
                style={{ marginTop: 8, marginBottom: 16, color: "#555" }}
              >
                Engine #{i + 1}
              </Typography>
              <FormControl fullWidth className={classes.fieldContainer}>
                {renderField("Serial/VIN", engine, (fieldName, newValue) => {
                  let newEngine = Object.assign({}, engine);
                  newEngine[fieldName] = newValue;
                  setEnginesData(
                    enginesData.map((e, j) => (j === i ? newEngine : e)),
                  );
                })}
              </FormControl>
              <FormControl fullWidth className={classes.fieldContainer}>
                {renderField(
                  "Item Description",
                  engine,
                  (fieldName, newValue) => {
                    let newEngine = Object.assign({}, engine);
                    newEngine[fieldName] = newValue;
                    setEnginesData(
                      enginesData.map((e, j) => (j === i ? newEngine : e)),
                    );
                  },
                )}
              </FormControl>
              <FormControl fullWidth className={classes.fieldContainer}>
                {renderField("Model Number", engine, (fieldName, newValue) => {
                  let newEngine = Object.assign({}, engine);
                  newEngine[fieldName] = newValue;
                  setEnginesData(
                    enginesData.map((e, j) => (j === i ? newEngine : e)),
                  );
                })}
              </FormControl>
              <FormControl fullWidth className={classes.fieldContainer}>
                {renderField("Item Amount", engine, (fieldName, newValue) => {
                  let newEngine = Object.assign({}, engine);
                  newEngine[fieldName] = newValue;
                  setEnginesData(
                    enginesData.map((e, j) => (j === i ? newEngine : e)),
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        ))}

        {!!errorMessage && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        )}
        <div className={classes.formButtons}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            endIcon={<ChevronRightIcon />}
            disabled={isLoading}
          >
            Generate NorthPoint Invoice CSV
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setSelectedFile(null);
              setShowCSVSelector(true);
              setCSVDealerName("");
              setCrossReferenceList([]);
              setErrorMessage("");
              setMatchedDealerName("");
            }}
            style={{ margin: 16 }}
          >
            Reset
          </Button>
        </div>
      </form>

      <Dialog
        open={showCrossReferenceTable}
        onClose={() => setShowCrossReferenceTable(false)}
        aria-labelledby="dealer-selection"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          Dealer Cross Reference List
          <IconButton
            style={{ float: "right" }}
            onClick={() => setShowCrossReferenceTable(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="cross reference table"
            >
              <TableHead>
                {crossReferenceList
                  .filter((row, i) => i === 0)
                  .map((row, i) => (
                    <TableRow key={`header-${i}`}>
                      {row.map((col, j) => (
                        <TableCell key={`header-${i}-${j}`}>{col}</TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {crossReferenceList
                  .filter((row, i) => i !== 0)
                  .map((row, i) => (
                    <TableRow
                      key={`content-${i}`}
                      hover
                      style={{ cursor: "pointer" }}
                    >
                      {row.map((col, j) => (
                        <TableCell
                          key={`content-${i}-${j}`}
                          onClick={() => {
                            let dealerName = row[8];
                            let dealerNumber = row[7];
                            let sloc = row[3];
                            let plantId = row[5];
                            let confirmation = `Select this dealer? ${dealerName}`;

                            if (window.confirm(confirmation)) {
                              let newCSVData = Object.assign({}, csvData);
                              newCSVData["SLOC ID"] = sloc;
                              newCSVData["Plant ID"] = plantId;
                              newCSVData["Dealer Number"] = dealerNumber;
                              setCSVDealerName(dealerName);
                              setCSVData(newCSVData);
                              setMatchedDealerName("");
                              setShowCrossReferenceTable(false);
                            }
                          }}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
